import { useEffect, useState } from 'react';
import FormInputText from '../uiComponents/FormInputText';
import { useForm } from 'react-hook-form';
import {
  setShowExamModal,
  useAddEventMutation,
  useGetEventQuery,
  useUpdateEventMutation,
} from '../../store';
import Select from '../uiComponents/Select';
import { useNavigate, useParams } from 'react-router-dom';
import MainHeading from '../uiComponents/MainHeading';
import { useDispatch } from 'react-redux';
import {
  provinceOptions,
  showLoadingEffect,
  showLoadingNotificationEffect,
} from '../../utils/helpers';

function EventForm() {
  const dispatch = useDispatch();
  const { eventId, eventType } = useParams();
  const navigate = useNavigate();
  const { register, handleSubmit, setValue, reset } = useForm({
    shouldUnregister: true,
  });
  const [selectedEventType, setSelectedEventType] = useState();
  const [selectedLocations, setSelectedLocations] = useState();
  const [selectedEventStatus, setSelectedEventStatus] = useState();
  const [addEvent, addEventResults] = useAddEventMutation();
  const { data, isLoading, isError } = useGetEventQuery(
    { eventId, eventType },
    {
      skip: !eventId,
    }
  );

  const [updateEvent, updateEventResults] = useUpdateEventMutation();

  const handleEventTypeChange = (option) => {
    setSelectedEventType(option);
  };
  const eventStatusOptions = [
    { label: 'Open', value: false },
    { label: 'Closed', value: true },
  ];

  useEffect(() => {
    showLoadingEffect(data, isLoading, isError);

    if (data) {
      const eventDate = new Date(data?.eventDate).toISOString().split('T')[0];
      const regStart = new Date(data?.regStart).toISOString().split('T')[0];
      const regEnd = new Date(data?.regEnd).toISOString().split('T')[0];
      setValue('title', data.title);
      setValue('eventDate', eventDate);
      setValue('regStart', regStart);
      setValue('regEnd', regEnd);
      setValue('ageLimit', data.ageLimit);
      setSelectedEventType({
        label: data.type,
        value: data.type,
      });
      setSelectedLocations([
        ...data.locations.map((location) => ({
          label: location,
          value: location,
        })),
      ]);

      setSelectedEventStatus(
        eventStatusOptions.filter((status) => status.value === data.closed)[0]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isError, setValue]);

  useEffect(() => {
    showLoadingNotificationEffect(updateEventResults, 'Event', 'Updating');
    if (updateEventResults.isSuccess) {
      dispatch(setShowExamModal());
    }
  }, [updateEventResults, navigate, dispatch]);

  useEffect(() => {
    showLoadingNotificationEffect(addEventResults, 'Event', 'Adding');

    if (addEventResults.isSuccess) {
      reset();
      handleEventTypeChange('');
      dispatch(setShowExamModal());
    }
  }, [addEventResults, dispatch, navigate, reset]);

  const eventTypeOptions = [
    { label: 'Examination', value: 'examination' },
    { label: 'Poomsae', value: 'poomsae' },
    { label: 'Senior Keurugi', value: 'keurugiSenior' },
    { label: 'Junior Keurugi', value: 'keurugiJunior' },
    { label: 'Cadet Keurugi', value: 'keurugiCadet' },
  ];

  const handleFormSubmit = (form) => {
    const formData = new FormData();
    form.type = selectedEventType.value;

    const body = {
      ...form,
      locations: selectedLocations.map((location) => location.value),
      image: form.image.length > 0 ? form.image[0] : data?.image,
    };

    Object.entries(body).forEach((entry) => {
      formData.append(entry[0], entry[1]);
    });
    if (eventId) {
      formData.append('closed', selectedEventStatus.value);
      updateEvent({ eventId, eventType, body: formData });
    } else addEvent(formData);
  };

  return (
    <form
      onClick={(e) => e.stopPropagation()}
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <div
        className={'flex flex-col gap-2 bg-gray-100 rounded-lg p-8 h-fit w-fit'}
      >
        <MainHeading>{`${!eventId ? 'Add Event' : 'Edit Event'}`}</MainHeading>
        <div className="flex flex-col md:grid md:grid-cols-2  gap-2 h-full md:mt-2">
          <FormInputText
            required
            label={'Title'}
            htmlFor={'title'}
            placeholder={'i.e. 12th Examination Round'}
            register={register}
          />
          <FormInputText
            required
            label={'Event Date'}
            htmlFor={'eventDate'}
            register={register}
            type={'date'}
          />
          <FormInputText
            required
            label={'Registeration Start Date'}
            htmlFor={'regStart'}
            register={register}
            type={'date'}
          />
          <FormInputText
            required
            label={'Registeration End Date'}
            htmlFor={'regEnd'}
            register={register}
            type={'date'}
          />

          <div className="flex flex-col gap-2">
            <label className="font-bold font-gray-800">Event Type</label>
            <Select
              value={selectedEventType}
              options={eventTypeOptions}
              onChange={(value) => handleEventTypeChange(value)}
              required
            />
          </div>
          <div className="flex flex-col gap-2">
            <label className="font-bold font-gray-800">Location</label>
            <Select
              value={selectedLocations}
              options={[{ label: 'Any', value: 'Any' }, ...provinceOptions]}
              onChange={(value) => setSelectedLocations(value)}
              required
              isMulti
            />
          </div>
          {selectedEventType && selectedEventType.value === 'poomsae' && (
            <FormInputText
              required
              label={'Age Limit'}
              htmlFor={'ageLimit'}
              register={register}
              type={'number'}
              min={0}
              placeholder="Enter age limit or 0 for no limit."
            />
          )}
          {eventId && (
            <div className="flex flex-col gap-2">
              <label className="font-bold font-gray-800">Status</label>
              <Select
                value={selectedEventStatus}
                options={eventStatusOptions}
                onChange={(value) => setSelectedEventStatus(value)}
              />
            </div>
          )}
          <div className="flex flex-col gap-2">
            <label htmlFor="image" className="font-bold">
              Image
            </label>
            <input
              required={!eventId}
              {...register('image')}
              type="file"
              name="image"
              id="image"
              className="upload file:bg-gray-700 file:font-bold file:text-white hover:file:bg-gray-600 hover:file:shadow file:cursor-pointer file:border-0 file:rounded-full file:py-2 file:px-4 file:mr-4 text-gray-500"
            />
          </div>

          <button className="col-span-2 self-end w-full mt-2 rounded hover:bg-gray-800 uppercase bg-gray-700 text-white font-bold py-2 px-2">
            submit
          </button>
        </div>
      </div>
    </form>
  );
}

export default EventForm;
